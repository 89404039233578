
@import '~@angular/material/theming';
@include mat-core();

/* http://mcg.mbitson.com */

$md-primary: (
    50 : #e1e6e8,
    100 : #b3c0c5,
    200 : #80969f,
    300 : #4d6b78,
    400 : #274c5b,
    500 : #012c3e,
    600 : #012738,
    700 : #012130,
    800 : #011b28,
    900 : #00101b,
    A100 : #59a7ff,
    A200 : #268bff,
    A400 : #0071f2,
    A700 : #0065d9,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-secondary: (
    50 : #e0f3f5,
    100 : #b3e1e7,
    200 : #80cdd7,
    300 : #4db9c6,
    400 : #26aaba,
    500 : #009bae,
    600 : #0093a7,
    700 : #00899d,
    800 : #007f94,
    900 : #006d84,
    A100 : #b1efff,
    A200 : #7ee5ff,
    A400 : #4bdaff,
    A700 : #31d5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* ======== Angular material custom themes ======== */ 
$my-custom-primary: mat-palette($md-primary, 500);
$my-custom-accent: mat-palette($md-secondary, 500);
$my-custom-warn: mat-palette($mat-red);

$my-custom-theme: mat-light-theme($my-custom-primary, $my-custom-accent, $my-custom-warn);

@include angular-material-theme($my-custom-theme);