.tableMaterial {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  th.mat-header-cell {
    text-align: center;
    padding: 0 5px;
    font-size: 13px;
    color: $primary;
    border-bottom: 2px solid $primary;
    border-top: 2px solid $primary;

  }

  .mat-footer-row,
  .mat-header-row,
  .mat-row {
    height: 50px;
  }
  .mat-cell{
    font-size: 12px;
    text-align: center;
    padding: 0 5px;
    border-color:  rgb(204, 203, 203);
    p{
      text-align: left ;
    }
  }

  .mat-row:hover .mat-cell {
    background-color: whitesmoke;
  }


}
@media(max-width: 1200px) {
    
  .tableMaterial {
    max-width: 100%;
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    tr{
      td,th{
        min-width: 130px;
      }
    }
  }
}
@media(max-width: 800px){
  .tableMaterial {
    tr{
      td,th{
        min-width: 90px;
      }
    }
  }
}