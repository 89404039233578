
.table-title{
    font-weight: bolder;
}

.table-contain{
    width: 430px;
    margin-bottom: 40px;
    table{
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
        td.table-param{
            border-bottom: solid 2px $secondary;
            padding-bottom: 5px;
            font-weight: bolder;
            
        }
        td.table-param2{
            text-align: right;
        }
        td.table-result2{
            text-align: right;
            padding-top: 7px;
        }
    }
}
@media (max-width: 600px) {
    .table-contain{
        width: 100%;
    }
}