.title_section{
    width: 90%;
    border-radius: 5px;
    background-color: $primary;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    mat-icon{
        color: $secondary;
    }
    h2{
        padding-left: 10px;
        font-size: 20px;
        font-weight: 300;
        margin: 0;
    }
}