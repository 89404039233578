.register-main{
    display: flex;
    justify-content: center;
    color: $primary;
  }
  
  .register-contain{
    width: 450px;
    padding: 30px;
    border-radius: 5px;
    background: #e7e7e763;
    margin: 40px;
    text-align: center;
    .title-register{
      font-weight: bolder;
      font-size: 20px;
    }
    .steps-contain{
      display: flex;
      justify-content: center;
      align-items: center;
      
      .step1{
        display: flex;
        background-color: $secondary;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        color: $white;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        margin: 10px;
        .step1-number{
          margin: 0;
          padding: 0;
        }
      }
      .step1-text{
        font-weight: bold;
        margin: 0;
        font-size: 15px;
      }
    }
  }
  
  .title-form{
    text-align: left;
    font-size: 15px;
    font-weight: bold;
  }
  
  .register-form{
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  
  .date-fieldset{
    display: flex;
    flex-direction: row;
    align-items: center;
    .date-input{
      width: 250px;
    }
  }
  
  
  fieldset {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: none;
      input{
        width: 300px;
      }
    }
  
  .text-number-form{
    margin-bottom: 20px;
    width: 100%;
    font-size: 12.9px;
  }
  
  .example-margin{
    text-align: left;
    margin-bottom: 40px;
    font-size: 15px;
  }
  
  .text-form{
    text-align: left;
    font-size: 15px;
  }

  mat-progress-bar{
    margin-bottom: 20px;
    width: 200px;
    align-self: center;
  }
  
  .logo-contain{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin: 0;
    .text-outform{
      margin: 0;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    img{
      width: 150px;
      align-self: center;
    }
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }