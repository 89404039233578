.button-form{
  width: 150px;
  margin-right: auto;
  margin-left: auto;
  transition: 0.5s;
  background-color: $primary;
  color: white;
  height: 40px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: medium;
  font-weight: 300;
  &:hover{
    background-color: $secondary;
  }
}
.button-form-secondary{
  width: 150px;
  height: 40px;
  margin-right: auto;
  margin-left: auto;
  transition: 0.5s;
  background-color: $secondary;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: medium;
  font-weight: 300;
  &:hover{
    background-color: $primary;
  }
  
}
.button-empty{
  width: 150px;
  height: 40px;
  margin-right: auto;
  margin-left: auto;
  transition: 0.5s;
  background-color: transparent;
  border-radius: 5px;
  border: none;
  color: $primary;
  cursor: pointer;
  font-size: medium;
  font-weight: 300;
  &:hover{
    background-color: rgb(229, 229, 229);
  }
}

